// Import Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
$container-max-widths: ( sm: 340px, md: 540px, lg: 720px, xl: 884px,
	xxl: 960px );
@import "../../node_modules/bootstrap/scss/containers";

// Custom Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Roboto:wght@400;700&display=swap');

// Custom CSS
@import "mmenu";
@import "mmenu-custom";
@import "tow";